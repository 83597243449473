.account-wrapper {
    font-family: Avenir Next,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #000;
    font-weight: 500;
  }
  
  .account-header {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #1a1a1a;
    background-color: #20222b;
    color: #c5cbd4;
  }
  
  .account-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
  }
  
  .account-sidebar {
    width: 240px;
    min-width: 240px;
    margin-top: 50px;
    background-color: #20222b;
    color: #c5cbd4;
  }
  
  .account-main {
    overflow: auto;
    width: 100%;
    height: 90vh;
    margin-top: 50px;
    padding: 0px;
    background-color: #fff;
  }
  
  .div-block {
    width: 240px;
    min-width: 240px;
    padding-left: 20px;
  }
  
  .heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
  }
  
  .account-nav-item {
    font-size: 16px;
    line-height: 45px;
    font-weight: 700;
    display: flex;
  }
  
  .account-nav-item:hover {
    background-color: #262831;
  }

  .account-nav-item .active {
    background-color: #262831;
    padding: 5px 20px;
    width:100%;
  }

  .account-nav-item a {
      padding: 5px 20px;
      color: #c5cbd4;
      font-weight: 500;
      width:100%;
  }
  
  .paragraph {
    margin-bottom: 0px;
    padding-left: 20px;
    font-weight: 500;
  }
  
  .account-settings {
    padding: 20px 40px 40px 60px;
    background-color: #fafafa;
    height: 100vh;
  }
  
  .account-main-header {
    padding: 40px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #fff;
    box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.21);
  }
  
  .heading-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 500;
  }
  
  .body {
    font-family: Roboto, sans-serif;
    color: #000;
  }
  
  .heading-3 {
    margin-bottom: 0px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 600;
  }
  
  .account-subtitle {
    color: #7d829c;
  }
  
  .account-subheader {
    padding-bottom: 10px;
  }
  
  .account-settings-container {
    margin-bottom: 60px;
  }
  
  .account-settings-main {
    display: block;
    padding: 20px 20px 0px;
    border-style: solid;
    border-width: 1px;
    border-color: #e6e6e6;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .heading-4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 500;
  }
  
  .paragraph-2 {
    margin-bottom: 0px;
    color: #7d829c;
    font-size: 14px;
  }
  
  .account-dropdown {
    width: 100%;
  }
  
  .dropdown-toggle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #e6e6e6;
    border-radius: 8px;
  }
  
  .account-back {
    font-size: 17px;
  }
  
  .div-block-2 {
    padding: 20px 20px 40px 15px;
    display: flex;
    align-items: center;
  }
  
  .div-block-3 {
    width: 50%;
  }
  
  .div-block-4 {
    width: 50%;
  }
  
  .account-col-6 {
    width: 50%;
  }
  
  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .paragraph-2-copy {
    margin-bottom: 0px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  
  .account-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .div-block-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .account-button-blue {
    border-radius: 8px;
    background-color: #5b53ff;
    box-shadow: 1px 8px 20px 0 rgba(117, 112, 255, 0.44);
    font-size: 17px;
    line-height: 28px;
    padding: 7px 20px;
    color: white;
    display: flex;
    align-items: center;
  }

  
  .account-button-blue:hover {
    background-color: #7a75ff;
    color: white;
  }
  
  .user-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .user-container:hover {
    background-color: #f2f5f7;
  }
  
  .account-user-avatar {
    border-radius: 50%;
  }
  
  .users-profile-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .paragraph-3 {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
  }
  
  .div-block-9 {
    padding-left: 10px;
  }
  
  .paragraph-4 {
    margin-bottom: 0px;
    color: #818691;
    font-size: 15px;
  }
  
  .paragraph-5 {
    margin-bottom: 0px;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 400;
  }
  
  .div-block-10 {
    width: 25%;
  }
  
  .paragraph-6 {
    margin-bottom: 0px;
    color: #a3a6a8;
    font-size: 16px;
  }
  
  .user-container-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .billing-container {
    padding: 40px 60px 20px 40px;
  }
  
  .billing-tabs-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
  }
  
  .div-block-11 {
    margin-right: 20px;
    padding-right: 0px;
    border-bottom: 2.5px solid #5b53ff;
    border-radius: 1px;
  }
  
  .div-block-12 {
    margin-right: 20px;
    padding-right: 0px;
  }
  
  .billing-main-header {
    padding: 20px 40px 0px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #fff;
    box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.21);
  }
  
  .paragraph-7 {
    color: #7d829c;
  }
  
  .paragraph-8 {
    color: #7d829c;
  }
  
  .billing-subscription-block {
    padding: 20px 20px 20px 25px;
    border-radius: 8px;
    background-color: #f1f3f6;
  }
  
  .heading-5 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    color: #2b2f3b;
    font-size: 19px;
    font-weight: 600;
  }
  
  .paragraph-9 {
    margin-bottom: 0px;
    color: #2b2f3b;
    font-size: 17px;
    line-height: 28px;
  }
  
  .paragraph-10 {
    margin-bottom: 0px;
    color: #2b2f3b;
    font-size: 17px;
    line-height: 28px;
  }
  
  .paragraph-11 {
    margin-bottom: 0px;
    color: #7d829c;
    font-size: 13px;
    text-align: right;
  }
  
  .div-block-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .button-white {
    background-color: #fff;
  }
  
  .button {
    border-radius: 8px;
    color: #5b53ff;
    font-weight: 600;
    padding: 10px 20px;
  }
  
  .button:hover {
    background-color: #f3f3f3;
  }
  
  .div-block-14 {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: right;
  }
  
  .div-block-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .div-block-16 {
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
    background-color: #e4e3ff;
    box-shadow: 1px 6px 20px -9px rgba(117, 112, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5b53ff;
  }
  
  .div-block-17 {
    margin-left: 20px;
    width:100%;
  }
  
  .paragraph-12 {
    margin-bottom: 0px;
    color: #2b2f3b;
    font-size: 19px;
    line-height: 28px;
    font-weight: 500;
  }
  
  .paragraph-13 {
    color: #2b2f3b;
    font-weight: 600;
  }
  
  .div-block-18 {
    margin-right: 20px;
  }
  
  .div-block-19 {
    margin-right: 20px;
  }
  
  .payment-method-container {
    padding: 0px 20px 20px;
  }
  
  .button-2 {
    margin-left: auto;
  }
  
  .div-block-20 {
    margin-left: auto;
  }
  
  .paragraph-14 {
    margin-bottom: 0px;
    color: #7d829c;
  }
  
  .paragraph-15 {
    color: #7d829c;
    font-size: 15px;
    line-height: 40px;
  }
  
  .div-block-21 {
    padding-left: 20px;
  }
  
  @media screen and (max-width: 479px) {
    .account-header {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .account-sidebar {
      display: none;
    }
  
    .account-main {
      margin-top: 134px;
    }
  
    .div-block {
      width: 100%;
      min-width: 0px;
      padding-left: 0px;
      text-align: center;
    }
  
    .account-settings {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .account-main-header {
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
    }
  
    .heading-2 {
      margin-bottom: 20px;
      font-weight: 600;
    }
  
    .account-col-6 {
      width: 100%;
    }
  
    .div-block-5 {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  
    .account-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .div-block-6 {
      text-align: center;
    }
  
    .div-block-7 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .user-container {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .account-user-avatar {
      display: none;
    }
  
    .paragraph-4 {
      overflow: visible;
      font-size: 12px;
    }
  
    .billing-main-header {
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
    }
  }